<template>
  <van-overlay z-index="99" :custom-style="{backgroundColor:' rgba(255, 255, 255, 0)'}" :show="true">
    <div class="wrapper">
      <van-loading type="spinner" color="#1989fa" />
    </div>
  </van-overlay>
</template>

<script>
export default {
  name: 'LOADING',
  data() {
    return {}
  },
}
</script>
<style lang="less" scoped>
// /deep/.van-overlay {
//   z-index: 99 !important;
//   background-color: rgba(0, 0, 0, 0) !important;
// }
.loading {
  position: fixed;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.164);
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 120px;
  height: 120px;
  background-color: #fff;
}
</style>