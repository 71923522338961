// import home from '@/views/home'
const router_modular = [
  // {
  //   path: '/',
  //   name: 'index',
  //   component: home,
  //   meta: { requireAuth: true },
  //   children: [
  //     {
  //       path: '/home',
  //       name: '首页',
  //       component: home,
  //       meta: { title: '宣傳首页', requireAuth: true },
  //     },
  //   ],
  // },
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/index'),
    meta: { requireAuth: true },
    children: [
      {
        path: '/index',
        name: 'index',
        component: () => import('@/views/index'),
        meta: { requireAuth: true },
      },
    ],
  },
  // {
  //   path: '/index',
  //   name: 'index',
  //   component: () => import('@/views/index'),
  //   meta: { requireAuth: true },
  // },
  {
    path: '*',
    name: 'NotFound',
    component: () => import('@/views/error/404'),
    meta: {
      title: '404',
      requireAuth: false,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login'),
    meta: {
      title: '登錄',
      requireAuth: false,
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/register'),
    meta: {
      title: '註冊',
      requireAuth: false,
    },
  },
  {
    path: '/account/revise',
    name: 'revise',
    component: () => import('@/views/account/revise'),
  },
  {
    path: '/account/verify',
    name: 'verify',
    component: () => import('@/views/account/verify'),
  },
  {
    path: '/account/revisePwd',
    name: 'revisePwd',
    component: () => import('@/views/account/revisePwd'),
  },
  {
    path: '/authentication/education',
    name: 'education',
    component: () => import('@/views/authentication/education'),
  },
  {
    path: '/authentication/grade',
    name: 'grade',
    component: () => import('@/views/authentication/grade'),
  },
  {
    path: '/authentication/subject',
    name: 'subject',
    component: () => import('@/views/authentication/subject'),
  },
  {
    path: '/authentication/topic',
    name: 'topic',
    component: () => import('@/views/authentication/topic'),
  },
  {
    path: '/curriculum/curriculum_list',
    name: 'curriculum_list',
    component: () => import('@/views/curriculum/curriculum_list'),
  },
  {
    path: '/curriculum/curriculum_state',
    name: 'curriculum_state',
    component: () => import('@/views/curriculum/curriculum_state'),
  },
  {
    path: '/curriculum/detail',
    name: 'detail',
    component: () => import('@/views/curriculum/detail'),
  },
  {
    path: '/curriculum/comment',
    name: 'comment',
    component: () => import('@/views/curriculum/comment'),
  },
  {
    path: '/system/user',
    name: 'user',
    component: () => import('@/views/system/user'),
  },
  {
    path: '/system/changeUser',
    name: 'changeUser',
    component: () => import('@/views/system/changeUser'),
  },
  {
    path: '/system/myCurriculum',
    name: 'myCurriculum',
    component: () => import('@/views/system/myCurriculum'),
  },
  {
    path: '/system/myOrder',
    name: 'myOrder',
    component: () => import('@/views/system/myOrder'),
  },
  {
    path: '/system/myCitation',
    name: 'myCitation',
    component: () => import('@/views/system/myCitation'),
  },
  {
    path: '/system/myCitation',
    name: 'myCitation',
    component: () => import('@/views/system/myCitation'),
  },
  {
    path: '/video/videoPlayer',
    name: 'videoPlayer',
    component: () => import('@/views/videoplayer'),
    meta: {
      title: '課程播放',
      requireAuth: true,
    },
  },
  {
    path: '/answer/chapter_exercise',
    name: 'chapter_exercise',
    component: () => import('@/views/answer/chapter_exercise'),
    meta: {
      title: '章節練習',
      requireAuth: true,
    },
  },
  {
    path: '/answer/mock_examination',
    name: 'mock_examination',
    component: () => import('@/views/answer/mock_examination'),
    meta: {
      title: '模擬考試',
      requireAuth: true,
    },
  },
  {
    path: '/answer/examination_first',
    name: 'mock_examination',
    component: () => import('@/views/answer/examination_first'),
    meta: {
      title: '模擬考試',
      requireAuth: true,
    },
  },
  {
    path: '/answer/examination',
    name: 'examination',
    component: () => import('@/views/answer/examination'),
    meta: {
      title: '考试',
      requireAuth: true,
    },
  },
  {
    path: '/answer/practice',
    name: 'practice',
    component: () => import('@/views/answer/practice'),
    meta: {
      title: '练习',
      requireAuth: true,
    },
  },
  {
    path: '/answer/settlement',
    name: 'settlement',
    component: () => import('@/views/answer/settlement'),
    meta: {
      title: '练习结算',
      requireAuth: true,
    },
  },
  {
    path: '/review',
    name: 'review',
    component: () => import('@/views/review'),
    meta: {
      title: '溫習',
      requireAuth: true,
    },
  },
  {
    path: '/review/review_topic',
    name: 'review_topic',
    component: () => import('@/views/review/review_topic'),
    meta: {
      title: '溫習题目',
      requireAuth: true,
    },
  },
  {
    path: '/answer/score',
    name: 'score',
    component: () => import('@/views/answer/score'),
    meta: {
      title: '考卷得分',
      requireAuth: true,
    },
  },
  {
    path: '/mycoins',
    name: 'mycoins',
    component: () => import('@/views/mycoins'),
    meta: {
      title: '我的金幣',
      requireAuth: true,
    },
  },
  {
    path: '/mycoins/recharge',
    name: 'recharge',
    component: () => import('@/views/mycoins/recharge'),
    meta: {
      title: '充值記錄',
      requireAuth: true,
    },
  },
  {
    path: '/mycoins/apply',
    name: 'apply',
    component: () => import('@/views/mycoins/apply'),
    meta: {
      title: '使用記錄',
      requireAuth: true,
    },
  },
  {
    path: '/mycoins/cash_desk',
    name: 'cash_desk',
    component: () => import('@/views/mycoins/cash_desk'),
    meta: {
      title: '支付方式',
      requireAuth: true,
    },
  },
  {
    path: '/writing',
    name: 'writing',
    component: () => import('@/views/writing'),
    meta: {
      title: '寫作',
      requireAuth: true,
    },
  },
  {
    path: '/writing/topic',
    name: 'topic',
    component: () => import('@/views/writing/topic'),
    meta: {
      title: '寫作題目',
      requireAuth: true,
    },
  },
  {
    path: '/writing/history_writing',
    name: 'history_writing',
    component: () => import('@/views/writing/history_writing'),
    meta: {
      title: '歷史寫作',
      requireAuth: true,
    },
  },
  {
    path: '/writing/writing_state',
    name: 'writing_state',
    component: () => import('@/views/writing/writing_state'),
    meta: {
      title: '寫作詳情',
      requireAuth: true,
    },
  },
  {
    path: '/writing/text_writing',
    name: 'text_writing',
    component: () => import('@/views/writing/text_writing'),
    meta: {
      title: '開始寫作',
      requireAuth: true,
    },
  },
  {
    path: '/writing/AIcomment',
    name: 'AIcomment',
    component: () => import('@/views/writing/AIcomment'),
    meta: {
      title: 'ai點評',
      requireAuth: true,
    },
  },
  {
    path: '/errorTopics',
    name: 'errorTopics',
    component: () => import('@/views/errorTopics'),
    meta: {
      title: '錯題庫',
      requireAuth: true,
    },
  },
  {
    path: '/errorTopics/alltopic',
    name: 'alltopic',
    component: () => import('@/views/errorTopics/alltopic'),
    meta: {
      title: '错题练习',
      requireAuth: true,
    },
  },
  {
    path: '/proposal',
    name: 'proposal',
    component: () => import('@/views/proposal'),
    meta: {
      title: '升學建議',
      requireAuth: true,
      keepAlive: true,
    },
  },
  {
    path: '/proposal/down_pdf',
    name: 'down_pdf',
    component: () => import('@/views/proposal/down_pdf'),
    meta: {
      title: 'PDF',
      requireAuth: true,
      keepAlive: false,
    },
  },
  {
    path: '/package',
    name: 'package',
    component: () => import('@/views/package'),
    meta: {
      title: '套餐',
      requireAuth: true,
    },
  },
  {
    path: '/transcript/transcript',
    name: 'transcript',
    component: () => import('@/views/transcript/transcript'),
    meta: {
      title: '成绩单',
      requireAuth: true,
    },
  },
  {
    path: '/PDF/school_pdf',
    name: 'school_pdf',
    component: () => import('@/views/PDF/school_pdf'),
    meta: {
      title: '升學建議',
      requireAuth: true,
    },
  },
  {
    path: '/PDF/citation_pdf',
    name: 'citation_pdf',
    component: () => import('@/views/PDF/citation_pdf'),
    meta: {
      title: '奖项',
      requireAuth: true,
    },
  },
  {
    path: '/analysisVideo',
    name: 'analysisVideo',
    component: () => import('@/views/analysisVideo'),
    meta: {
      title: '视频解析',
      requireAuth: true,
    },
  },
  {
    path: '/tos',
    name: 'tos',
    component: () => import('@/views/tos'),
    meta: {
      title: '服務條款',
      requireAuth: true,
    },
  },
  {
    path: '/policy',
    name: 'policy',
    component: () => import('@/views/policy'),
    meta: {
      title: '隱私政策',
      requireAuth: true,
    },
  },
  {
    path: '/intelligentLearning',
    name: 'intelligent',
    component: () => import('@/views/intelligentLearning'),
    meta: {
      title: '智能螺旋學習引擎',
      requireAuth: true,
      keepAlive:false
    },
  },
  {
    path: '/teachingFilm',
    name: 'teachingFilm',
    component: () => import('@/views/teachingFilm'),
    meta: {
      title: '教學影片',
      requireAuth: true,
    },
  },
  {
    path: '/suitangPractice',
    name: 'suitangPractice',
    component: () => import('@/views/teachingFilm/practice'),
    meta: {
      title: '随堂练习',
      requireAuth: true,
    },
  },
  {
    path: '/teachingVideo',
    name: 'teachingVideo',
    component: () => import('@/views/teachingFilm/teachingVideo'),
    meta: {
      title: '随堂練習教學影片',
      requireAuth: true,
    },
  },
  {
    path: '/errorTopicVideo',
    name: 'errorTopicVideo',
    component: () => import('@/views/teachingFilm/errorTopicVideo'),
    meta: {
      title: '错题重答教学影片',
      requireAuth: true,
    },
  },
  {
    path: '/suitang/analysisVideo',
    name: 'analysisVideo',
    component: () => import('@/views/teachingFilm/analysisVideo'),
    meta: {
      title: '随堂練習解析影片',
      requireAuth: true,
    },
  },
  {
    path: '/errorAnalysisVideo',
    name: 'errorAnalysisVideo',
    component: () => import('@/views/teachingFilm/errorAnalysisVideo'),
    meta: {
      title: '错题视频解析',
      requireAuth: true,
    },
  },
  {
    path: '/selfadaption',
    name: 'selfadaption',
    component: () => import('@/views/teachingFilm/selfadaption'),
    meta: {
      title: '螺旋自適應操練',
      requireAuth: true,
    },
  },
  {
    path: '/examination/examination_first',
    name: 'examination_first',
    component: () => import('@/views/teachingFilm/component/examination_first'),
    meta: {
      title: '螺旋模拟考试准备',
      requireAuth: true,
    },
  },
  {
    path: '/newmodule/examination',
    name: 'newexamination',
    component: () => import('@/views/teachingFilm/examination'),
    meta: {
      title: '螺旋模拟考试',
      requireAuth: true,
    },
  },
  {
    path: '/examination/score',
    name: 'examination_score',
    component: () => import('@/views/teachingFilm/score'),
    meta: {
      title: '螺旋模拟考试',
      requireAuth: true,
    },
  },
  {
    path: '/examination/error',
    name: 'examination_error',
    component: () => import('@/views/teachingFilm/errorTopic'),
    meta: {
      title: '錯題重答',
      requireAuth: true,
    },
  }
]
export default router_modular
